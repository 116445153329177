import React, { useContext } from 'react'
import { 
  Box,
  Flex,
  Container,
  Input,
  FormControl,
} from '@chakra-ui/react'
import { spacing, colors } from '../theme/themeAlopex'
import { H2 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
import NewsletterIcon from './NewsletterIcon'

const Newsletter = ({ title, copy, origin }) => {
  const { color } = useContext(ColorContext)

  return (
    <Box backgroundColor={color.alpha} p={[`${spacing.groups} 0`, null, `${spacing.section} 0`]} color={colors.brand.white}>
      <Container textAlign="center">
        <Flex justifyContent="center" mb={spacing.elements}>
          <NewsletterIcon maxWidth="100px" />
        </Flex>
        <H2 textTransform="uppercase" marginBottom="20px">{title}</H2>
        <Box as="p" mb={spacing.elements}>{copy}</Box>
        <form name="Newsletter" method="POST" data-netlify="true" netlify>
          <Flex flexDirection="row">
            <FormControl id="emailAddress" marginRight="10px" flex="3 1" isRequired>
              <Input type="email" name="email" placeholder="Your Email" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <input type="hidden" name="form-name" value="Newsletter" />
            <input type="hidden" name="origin" value={origin} />
            <Input
              type="submit"
              value="Submit"
              _hover={{
                color: color.alpha,
              }}
              flex="1 1"
            />
          </Flex>
        </form>
      </Container>
    </Box>
  )
}

export default Newsletter