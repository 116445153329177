import React, { useContext, useEffect, useRef, createRef } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Box,
  SimpleGrid,
} from '@chakra-ui/react'
import { H1, H2 } from '../theme/Headings'
import { spacing, breakpoints, hexToRGB } from '../theme/themeAlopex'
import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'
// Case Studies
import SVGIconMTAF from '../images/casestudies/icon_mtaf.svg'
import SVGLogoMTAF from '../images/casestudies/logo_mtaf.svg'
import SVGIconCOP from '../images/casestudies/icon_cop.svg'
import SVGLogoCOP from '../images/casestudies/logo_cop.svg'
import SVGIconKTVA from '../images/casestudies/icon_ktva.svg'
import SVGLogoKTVA from '../images/casestudies/logo_ktva.svg'
import SVGIconAKNAV from '../images/casestudies/icon_aknav.svg'
import SVGLogoAKNAV from '../images/casestudies/logo_aknav.svg'
import SVGIconChalet from '../images/casestudies/icon_chalet.svg'
import SVGLogoChalet from '../images/casestudies/logo_chalet.svg'
import SVGIconK2 from '../images/casestudies/icon_k2.svg'
import SVGLogoK2 from '../images/casestudies/logo_k2.svg'
import SVGIconMSO from '../images/casestudies/icon_mso.svg'
import SVGLogoMSO from '../images/casestudies/logo_mso.svg'
import Clients from '../components/Clients'
// Newsletter
import Newsletter from '../components/Newsletter'
// gsap
import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const CaseStudiesPage = ({ data }) => {
  // Case studies
  const { color } = useContext(ColorContext)

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  const refStudiesIcon = useRef([])
  const refStudiesLogo = useRef([])
  const studies = [
    {
      icon: SVGIconMTAF,
      logo: SVGLogoMTAF,
      title: 'MTA Foundation',
      link: '/mta-foundation-branding-website',
      background: data.mtafbg.childImageSharp.fluid
    },
    {
      icon: SVGIconCOP,
      logo: SVGLogoCOP,
      title: 'City of Palmer',
      link: '/city-of-palmer-website-palmerak-org',
      background: data.copbg.childImageSharp.fluid
    },
    {
      icon: SVGIconKTVA,
      logo: SVGLogoKTVA,
      title: 'KTVA 11',
      link: '/ktva-channel-11-news-website',
      background: data.ktvabg.childImageSharp.fluid
    },
    {
      icon: SVGIconAKNAV,
      logo: SVGLogoAKNAV,
      title: 'Alaska Navigator',
      link: '/alaskanavigator-org',
      background: data.aknavbg.childImageSharp.fluid
    },
    {
      icon: SVGIconChalet,
      logo: SVGLogoChalet,
      title: 'Sheldon Chalet',
      link: '/sheldon-chalet',
      background: data.chaletbg.childImageSharp.fluid
    },
    {
      icon: SVGIconK2,
      logo: SVGLogoK2,
      title: 'Fly K2',
      link: '/k2-aviation',
      background: data.k2bg.childImageSharp.fluid
    },
    {
      icon: SVGIconMSO,
      logo: SVGLogoMSO,
      title: 'Midnight Sun Oncology',
      link: '/midnight-sun-oncology',
      background: data.msobg.childImageSharp.fluid
    },
  ]
  
  refStudiesIcon.current = studies.map(() => createRef())
  refStudiesLogo.current = studies.map(() => createRef())
  const tl = []
  useEffect(() => {
    for (let i = 0; i < studies.length; i++) {
      tl[i] = gsap.timeline({paused: true})
      tl[i].to(refStudiesIcon.current[i].current, {
        scale: 2,
        opacity: 0,
      })
      tl[i].to(refStudiesLogo.current[i].current, {
        scale: 1,
        opacity: 1,
        delay: -0.2,
      })
    }
  })

  const gsapByecon = (i, direction = 'in') => {
    if (direction === 'in') {
      tl[i].play()
    } else {
      tl[i].reverse()
    }
  }

  const ogImage = !!data.contentJson.seoFields.ogImage ? `https://www.alopexid.com${data.contentJson.seoFields.ogImage.replace('/static', '')}"` : null

  return (
    <Layout>
      <SEO title={data.contentJson?.seoFields?.seoTitle} description={data.contentJson?.seoFields?.seoDesc} ogImage={ogImage} />
      {/* SERVICES */}
      <Container maxWidth={breakpoints.xl} pt={spacing.header}>
        <H1 variant="pageHeader" color={color.alpha}>Case Studies</H1>
        <H2 textAlign="center" mb={spacing.groups}>
          We’re proud of every project we do,
          <Box as="br" display={['none', null, 'block']} />
          but we’re especially proud of these ones.
        </H2>
      </Container>
      <SimpleGrid
        columns={[1, 2, 3]}
        gap='5px'
        mb={[spacing.groups, null, spacing.section]}
        sx={{
          '.study': {
            height: 0,
            paddingTop: '56.25%',
            position: 'relative',
            overflow: 'hidden',
            cursor: 'pointer',
            '&::after': {
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '200%',
              left: 0,
              top: '-100%',
              backgroundImage: `linear-gradient(to top, ${hexToRGB(color.alpha, 0.95)}, ${hexToRGB(color.alpha, 0.6)}, ${hexToRGB(color.alpha, 0.01)})`,
              transition: 'top 0.6s ease-in-out',
              zIndex: 10,
            },
            '&:hover': {
              '&::after': {
                top: '30%',
                pointerEvents: 'none',
              }
            },
            '.studyBG': {
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              '&::before, &::after': {
                backgroundSize: '105%',
                transition: 'opacity 0.5s ease, background-size 1s ease-in-out'
              },
              '&:hover': {
                '&::before, &::after': {
                  backgroundSize: '100%',
                }
              }
            },
            '.studyIcon, .studyLogo': {
              position: 'absolute',
              top: '50%',
              left: '50%',
              maxWidth: '25%',
              width: '100%',
              height: 'auto',
              zIndex: 20,
              transform: 'translate(-50%, -50%)',
              transformOrigin: '50% 50%',
              pointerEvents: 'none',
            },
            '.studyLogo': {
              maxWidth: '60%',
              opacity: 0,
              transform: 'translate(-50%, -50%) scale(0.5)',
            },
            '.studyLogo1': {
              maxWidth: '40%',
            },
            '.studyIcon1': {
              maxWidth: '20%',
            },
            '.studyIcon2': {
              maxWidth: '17%',
            },
            '.studyIcon3': {
              maxWidth: '20%',
            },
          }
        }}
      >
        {studies.map((study, i) => {
          return (
            <Link to={study.link} key={`study-key-${i}`}>
              <Box className="study" onMouseOver={() => { gsapByecon(i) }} onMouseOut={() => { gsapByecon(i, 'out') }}>
                <BackgroundImage className="studyBG" fluid={study.background} style={{position: ''}} />
                <Box as="img" ref={refStudiesLogo.current[i]} className={`studyLogo studyLogo${i}`} src={study.logo} alt={study.title} />
                <Box as="img" ref={refStudiesIcon.current[i]} className={`studyIcon studyIcon${i}`} src={study.icon} alt={study.title} />
              </Box>
            </Link>
          )
        })}
      </SimpleGrid>
      {/* Select clients */}
      <Container maxWidth={breakpoints.xl}>
        <H2 textAlign="center" mb={spacing.elements}>Select Clients</H2>
        <Clients />
      </Container>
      <Newsletter title={data.contentJson.title} copy={data.contentJson.newsletterCopy} origin="case-studies" />
      <Footer mode="bg01" />
    </Layout>
  )
}

export const query = graphql`
  query aloStudies {
    site {
      siteMetadata {
        title
      }
    }
    contentJson(slug: {eq: "case-studies"}) {
      title
      newsletterCopy
      seoFields {
        seoTitle
        seoDesc
        ogImage
      }
    }
    mtafbg: file(relativePath: { eq: "casestudies/bg_mtaf.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    copbg: file(relativePath: { eq: "casestudies/bg_cop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ktvabg: file(relativePath: { eq: "casestudies/bg_ktva.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    aknavbg: file(relativePath: { eq: "casestudies/bg_aknav.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chaletbg: file(relativePath: { eq: "casestudies/bg_sheldon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    k2bg: file(relativePath: { eq: "casestudies/bg_k2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    msobg: file(relativePath: { eq: "casestudies/bg_midnightsun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 80, ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CaseStudiesPage
