import React, { useRef, useEffect, useContext, useState } from 'react'
import ColorContext from '../context/ColorContext'
import {uniqueId} from 'lodash'
// gsap
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const NewsletterIcon = ({ maxWidth }) => {
	const [ rand ] = useState(uniqueId)
	const { color } = useContext(ColorContext)
	const refOpener = useRef(null)
	const refOpener2 = useRef(null)
	const refLetter = useRef(null)
	const openLetter = () => {
		gsap.set(refOpener.current, {transformOrigin: '50% 100%', scaleY: '-1'})
		gsap.set(refOpener2.current, {transformOrigin: '50% 100%', scaleY: '0'})
		const tlNL = new gsap.timeline({})
		tlNL.to(refOpener.current, {
			scrollTrigger: {
				trigger: refOpener.current,
				start: 'top 95%',
				end: 'top 75%',
				markers: false,
				scrub: 0.5,
			},
			scaleY: 0,
		})
		tlNL.to(refOpener2.current, {
			scrollTrigger: {
				trigger: refOpener2.current,
				start: 'top 75%',
				end: 'top 60%',
				markers: false,
				scrub: 0.5,
			},
			scaleY: 1,
		})
		tlNL.to(refLetter.current, {
			scrollTrigger: {
				trigger: refLetter.current,
				start: 'top 75%',
				end: 'top 30%',
				markers: false,	
				scrub: 0.5,
			},
			y: '-44px'
		})
	}

	useEffect(() =>  {
		openLetter()
	}, [])

	return (
		<svg
			id="IconNewsletter"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 109.2593 123.8152"
			x="0px"
			y="0px"
			style={{
				maxWidth: maxWidth,
				width: '100%',
			}}
		>
			<defs>
				<mask id={`letterMask_${rand}`} maskUnits="userSpaceOnUse">
					<polygon fill="#FFFFFF" points="10.5925,61.7413 54.8518,106 98.8889,61.963 98.8889,2.6143 10.5925,3.0073"/>
				</mask>
			</defs>
			<path fill={color.bravo} d="M106.1404,120.9593H3.5627V57.1488h102.5776V120.9593z"/>
			<linearGradient id={`envTop_${rand}`} gradientUnits="userSpaceOnUse" x1="72.8449" y1="16.4082" x2="36.4004" y2="102.186">
				<stop offset="0" style={{stopColor:color.alpha}}/>
				<stop offset="1" style={{stopColor:color.bravo}}/>
			</linearGradient>
			<polygon id="envTop" fill={`url(#envTop_${rand})`} points="102.1409,58.7575 7.5629,58.7575 54.8519,106.0464 "/>
			<polygon id="envOpener2" ref={refOpener2} fill={color.bravo} points="54.8518,2.6667 3.5627,57.1488 106.1404,57.1488 "/>
			<g id="letter" mask={`url(#letterMask_${rand})`}>
				<g ref={refLetter}>
					<rect id="paper" x="15.5927" y="63.0464" fill="#FFFFFF" width="78.5185" height="101.6943"/>
					<line id="line01" fill="none" stroke="#E5E5E5" strokeWidth="6" strokeMiterlimit="10" x1="28.4816" y1="80.8152" x2="81.2223" y2="80.8152"/>
					<line id="line02" fill="none" stroke="#E5E5E5" strokeWidth="6" strokeMiterlimit="10" x1="28.4816" y1="95.1859" x2="81.2223" y2="95.1859"/>
				</g>
			</g>
			<polygon id="envOpener" ref={refOpener} fill={color.bravo} points="54.8518,2.6667 3.5627,57.1488 106.1404,57.1488 "/>
			<linearGradient id={`envLeft_${rand}`} gradientUnits="userSpaceOnUse" x1="29.1158" y1="60.4702" x2="4.2269" y2="106.0998">
				<stop  offset="0" style={{stopColor:color.alpha}}/>
				<stop  offset="1" style={{stopColor:color.bravo}}/>
			</linearGradient>
			<polygon id="envLeft" fill={`url(#envLeft_${rand}`} points="7.5629,58.7575 36.6638,87.8583 7.5629,116.9592 "/>
			<linearGradient id={`envRight_${rand}`} gradientUnits="userSpaceOnUse" x1="78.6174" y1="60.7458" x2="105.2841" y2="98.0791">
				<stop  offset="0" style={{stopColor:color.alpha}}/>
				<stop  offset="1" style={{stopColor:color.bravo}}/>
			</linearGradient>
			<polygon id="envRight" fill={`url(#envRight_${rand})`} points="102.1409,58.7575 73.04,87.8584 102.1409,116.9592 "/>
			<linearGradient id={`envBottom_${rand}`} gradientUnits="userSpaceOnUse" x1="77.1977" y1="64.3646" x2="46.4111" y2="136.8259">
				<stop  offset="0" style={{stopColor:color.alpha}}/>
				<stop  offset="1" style={{stopColor:color.bravo}}/>
			</linearGradient>
			<polygon id="envBottom" fill={`url(#envBottom_${rand})`} points="54.852,106.0465 36.6637,87.8582 7.5629,116.9592 102.1408,116.9592 
				73.04,87.8582 "/>
		</svg>
	)
}

export default NewsletterIcon