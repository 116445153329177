import React, { useEffect } from 'react'
import { Box, SimpleGrid } from '@chakra-ui/react'
import { spacing } from '../theme/themeAlopex'
// Clients
import SVGClientMTA from '../images/clients/mta.svg'
import SVGClientBaileys from '../images/clients/baileys.svg'
import SVGClientK2 from '../images/clients/k2.svg'
import SVGClientCOP from '../images/clients/cop.svg'
import SVGClientThompson from '../images/clients/thompson.svg'
import SVGClientChalet from '../images/clients/chalet.svg'
import SVGClientMTAF from '../images/clients/mtaf.svg'
import SVGClientRusts from '../images/clients/rusts.svg'
import SVGClientAKSledDogTours from '../images/clients/aksleddogtours.svg'
import SVGClientMckinley from '../images/clients/mckinley.svg'
import SVGClientHPV from '../images/clients/hpv.svg'
import SVGClientDallyMac from '../images/clients/dallymac.svg'
// gsap
import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const Clients = () => {
  useEffect(() => {
    gsap.from('.clients .client', {
      scrollTrigger: {
        trigger: '.clients',
        start: 'top 75%',
      },
      duration: 0.5,
      scale: 0,
      opacity: 0,
      ease: 'power1.inOut',
      stagger: {
        amount: 1,
      },
    })
  }, [])
  return (
    <SimpleGrid
      className="clients"
      columns={[2, 3, 4]}
      columnGap={spacing.icon}
      rowGap={0}
      mb={[spacing.groups, null, spacing.section]}
      sx={{
        '.client': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          img: {
            width: '100%',
            maxWidth: '220px',
          }
        }
      }}
    >
      <Box className="client"><img src={SVGClientMTA} alt={'MTA'} /></Box>
      <Box className="client"><img src={SVGClientBaileys} alt={'Baileys Furniture'} /></Box>
      <Box className="client"><img src={SVGClientK2} alt={'K2'} /></Box>
      <Box className="client"><img src={SVGClientCOP} alt={'City of Palmer'} /></Box>
      <Box className="client"><img src={SVGClientThompson} alt={'Thompson & Co'} /></Box>
      <Box className="client"><img src={SVGClientChalet} alt={'Sheldon Chalet'} /></Box>
      <Box className="client"><img src={SVGClientRusts} alt={'Rusts'} /></Box>
      <Box className="client"><img src={SVGClientMTAF} alt={'MTA Foundation'} /></Box>
      <Box className="client"><img src={SVGClientAKSledDogTours} alt={'AK Sled Dog Tours'} /></Box>
      <Box className="client"><img src={SVGClientMckinley} alt={'McKinley Capital'} /></Box>
      <Box className="client"><img src={SVGClientHPV} alt={'Hatcher Pass Village'} /></Box>
      <Box className="client"><img src={SVGClientDallyMac} alt={'DallyMac & Cheese'} /></Box>
    </SimpleGrid>
  )
}

export default Clients